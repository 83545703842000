.title-login{
    color: #1A202C;
}
.logo-login{
    margin: auto;
}
.input-login{
    border-radius: 5px;
    padding: 8px 10px;
    border: 1px solid #E8E8E8;
    width:100%
}
.form{
    .title{
        color: #4A5568;
    }
}
.radio-login{
    width: 15px;
    height: 15px;
}
.title-forgot{
    color :#2C5282;
    cursor: pointer;
}
.button-forgot{
    width: 100%;
    padding:15px;
    background-color: #197BBD;
    color: white;
    border-radius:5px;
    border:none
}
.content-auth{
    margin-top: 7.5rem;
}
.logo-auth-style{
    display: flex;
    justify-content: center;
}

//reset-password
.reset-banner{
    margin-bottom: 60px;
}
.reset-container{
    .title-reset{
        font-weight: bold;
        font-size: 36px;
    }
    .btn-reset{
        background-color: #489E13;
        border-radius: 20px;
        color: white;
        border: none;
        padding: 15px 55px;
    }
    .wrap-code{
        width: 75%;
    }
    .my-verification-code{
        width: 100% !important;
        margin: auto;
        input{
            border-radius: 6px;
            margin-right: 20px;
            border: 1px solid #E8E8E8;
            width: 50px !important;
            height: 50px !important;
        }
        input:last-child{
            margin-right: 0;
            border-right: 1px solid #E8E8E8;
        }
        input:last-child:focus{
            border: 1px solid #006fff;
        }
    }
}
.reset-container.step-3{
    min-width: 400px;
}

@media only screen and (max-width: 990px){
    .mobile-none{
        background: none !important;
    }
}
@media only screen and (max-width: 480px){
    .title-login{
        font-size:14px !important;
        text-align: center;
        margin-top: 2.75rem !important;
    }
    .content-auth{
        margin-top: 0 ;
    }
    .reset-container{
        .my-verification-code{
                input{
                    width: 50px !important;
                    height: 50px !important;
                    margin-right: 10px;
                }
        }
        .wrap-code{
            width: 100%;
        }
    }
    .reset-container.step-3{
        min-width: 380px;
    }
}


@media only screen and (max-width: 390px){
    .reset-container{
        .my-verification-code{
                input{
                    width: 45px !important;
                    height: 45px !important;
                }
        }
    }
}
@media only screen and (max-width: 365px){
    .reset-container{
        .my-verification-code{
                input{
                    width: 40px !important;
                    height: 40px !important;
                }
        }
    }
}