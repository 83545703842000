
body{
    font-family: Roboto, "Helvetica Neue" ;
    .content{
        padding: 25px 0;
        padding-top:0
    }
}

.cursor-pointer{
    cursor:pointer;
}
.brand-logo{
    width: 100%;
      img{
        width: 100%;
      }
  }
  .aside.aside-left.aside-fixed.d-flex.flex-column.flex-row-auto{
      padding-top: 50px;
  }


.my-input{
    border: 2px solid #e8e8e8;
    padding: 5px 10px;
    width: 100%;
    border-radius:5px
}
.my-input:focus-visible{
    outline: none;
}
.my-button{
    border: 1px solid #197BBD;
    padding: 8px 13px;
    border-radius:10px;
    background-color: #197BBD;
}
.my-button.btn-password{
    background-color: #333333;
}

    // custom pagination
.my-navigation{
    .MuiPagination-ul{
        li{
            border: 1px solid #e8e8e8;
            margin-right: 8px;
            border-radius:4px;
            .MuiPaginationItem-root{
                margin: 0px;
                padding: 0px;
                color: #212B36;
                font-weight: 700;
            }
            .MuiPaginationItem-ellipsis{
                height: 32px !important;
                padding-top: 5px;
            }
        }
        li:last-child{
            margin-right: 0;
        }
    }
    .MuiPaginationItem-page.Mui-selected{
    background-color: #FFFFFF !important;
    border-radius: 4px;
    border: 1px solid #333333 !important;
    }
    .MuiPaginationItem-page.Mui-disabled{
        background-color: #C8CFD5 !important;
        border-radius: 0;
    }
}

.my-table{
    tbody{
        tr:hover{
            background-color: #E6ddcb38;
        }
        tr:nth-child(1),
        tr:nth-child(3),
        tr:nth-child(5),
        tr:nth-child(7),
        tr:nth-child(9){
            background-color: #E6ddcb38;
        }
    }
}
// My select-2
.my-select2{
    .css-yk16xz-control{
        min-height: 0;
        height: 33px;
    }
    .css-1okebmr-indicatorSeparator{
        align-self: unset !important;
    }
    .css-1hb7zxy-IndicatorsContainer{
        height:100%
    }
}


.font-slab{
    font-family: "Roboto Slab" !important;
}
.font-roboto{
    font-family: "Roboto" !important;
}

//Color 

.color-gray{
    color: #E8E8E8;
}
.dark-gray{
    color: #212B36
}
.color-gray-dark{
    color: #A6A6A6;
}
.dark-primary{
    color: #404040;
}
.blue-primary{
    color: #197BBD;
}
.dark-33{
    color: #333333;
}
.dark-blue{
    color: #197BBD;
}
.green-primary{
    color: #489E13;
}

//Text size

.size-12{
    font-size: 12px !important;
}
.size-13{
    font-size: 13px !important;
}
.size-14{
    font-size: 14px !important;
}
.size-16{
    font-size: 16px !important;
}
.size-18{
    font-size: 18px !important;
}
.size-20{
    font-size: 20px !important;
}
.size-24{
    font-size: 24px;
}
.size-30{
    font-size: 30px;
}

// font Weight

.font-weight-900{
    font-weight: 900 !important;
}
.font-weight-700{
    font-weight: 700 !important;
}
.font-weight-600{
    font-weight: 600 !important;
}
.font-weight-500{
    font-weight: 500 !important;
}

// Border radius

.radius-10{
    border-radius: 10px !important;
}
@media (min-width: 992px){
    .header-fixed.subheader-fixed.subheader-enabled{
        .wrapper {
            padding-top: 90px;
        }
    }
}
//menu

.aside-menu{
    .menu-nav{
        .menu-item.menu-item-active{
            .menu-heading{
                .menu-text{
                    color: #489E13 !important;
                }
            }
            .menu-link{
                .menu-text,.menu-icon.svg-icon{
                    color: #489E13 !important;
                }
            }
        }
        .menu-item:hover,.menu-item:active{
            .menu-link{
                .svg-icon.menu-icon,.menu-text{
                    color: #489E13 !important;
                }
            }
        }
    }
}

//Notification active

.notificate-active::after{
    content: "";
    width: 5px;
    height: 5px;
    background-color: red;
    position: absolute;
    top: 0; right: -3px;
    border-radius: 50%;
}

